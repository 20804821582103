import constants from '~/constants'

const messages = {
  failedToCopyLink: 'Не удалось скопировать ссылку.',
  linkIsCopied: 'Ссылка скопирована.'
}

export function useSharing () {
  const { $toast } = useNuxtApp()
  const route = useRoute()
  const router = useRouter()

  function copyLink (link: string): void {
    const { failedToCopyLink, linkIsCopied } = messages

    if (navigator.clipboard) {
      navigator.clipboard.writeText(link).then(
        function (): void {
          $toast.success(linkIsCopied)
        },
        function (): void {
          $toast.error(failedToCopyLink)
        }
      )
    } else if ((window as any).clipboardData) {
      (window as any).clipboardData.setData('Text', link)

      $toast.success(linkIsCopied)
    } else {
      const textArea = document.createElement('textarea')
      // textArea.value = link
      textArea.style.left = '-999999px'
      textArea.style.position = 'fixed'
      textArea.style.top = '-999999px'
      textArea.textContent = link

      document.body.appendChild(textArea)

      const selection = document.getSelection()
      const range = document.createRange()
      range.selectNode(textArea)

      if (selection) {
        selection.removeAllRanges()
        selection.addRange(range)
      }

      textArea.focus()
      textArea.select()

      try {
        if (document.execCommand('copy')) {
          $toast.success(linkIsCopied)
        } else {
          $toast.error(failedToCopyLink)
        }
      } catch (error) {
        $toast.error(failedToCopyLink)
      }

      if (selection) {
        selection.removeAllRanges()
      }

      document.body.removeChild(textArea)
    }
  }

  async function parsePromoCodeFromQuery (): Promise<void> {
    const query = { ...route.query }
    const { promoCode } = query

    if (promoCode !== undefined) {
      delete query.promoCode

      await router.replace({ query })

      if (typeof promoCode === 'string') {
        useCookie('promoCode', constants.cookieOptions).value = promoCode
      }
    }
  }

  return { copyLink, parsePromoCodeFromQuery }
}
